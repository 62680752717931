/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  TextInput,
  required,
  useRefresh,
  useUpdate,
  useGetIdentity,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import { Card, CardHeader, Divider, Typography, Box, InputAdornment, Icon } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styles from '../hooks/useContactPopupStyles';
import styles2 from '../hooks/useContactListStyles';
import { useAssigneeRoleOptions, useAssigneesOptions } from 'shared/hooks/useAppraisalOptions';
import { Assignee_Roles, User_Profiles } from 'shared/generated/types';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import { DateInput } from 'shared/components/Pickers';
import { displayFormattedDate, formatDecimalNumber } from 'shared/utils';
import { useAssigneeCommission, useGenericCommissionQuery } from 'shared/hooks/useAppraisalFees';

type EditCommissionProps = {
  label?: string;
  appraisal?: any;
  active?: boolean;
  clientId?: string;
  currentCommission?: any;
  onSave?: any;
  reportFee?: any;
  refetchCommission?: any;
  hideEditButton?: boolean;
  triggerCloseModal?: any;
};
interface State {
  quantity: number;
  rate: number;
}

function EditCommissionButton({
  appraisal,
  currentCommission,
  clientId,
  onSave,
  reportFee,
  refetchCommission,
  hideEditButton = false,
  triggerCloseModal,
}: EditCommissionProps) {
  const popUpClasses = styles();
  const classes = styles2();

  const [showDialog, setShowDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isFlat, setFlat] = useState(currentCommission.rate_type_id === 2);
  const [isGross, setGross] = useState(currentCommission.net_expenses ? false : true);
  const [assigneeId, setAssigneeId] = useState('');
  const [roleId, setRoleId] = useState(0);

  const [values, setValues] = useState<State>({
    quantity: currentCommission.quantity,
    rate:
      currentCommission.rate_type_id === 1
        ? formatDecimalNumber(currentCommission.rate * 100, 2)
        : currentCommission.rate,
  });
  const [percentage, setPercentage] = useState({
    gross:
      currentCommission.rate_type_id === 1 && !currentCommission.net_expenses
        ? formatDecimalNumber(currentCommission.rate * 100, 2)
        : 0,
    net:
      currentCommission.rate_type_id === 1 && currentCommission.net_expenses
        ? formatDecimalNumber(currentCommission.rate * 100, 2)
        : 0,
  });
  const { identity } = useGetIdentity();
  const [assigneeOptions] = useAssigneesOptions();
  const [getAssigneeRoleOptions, { data: assigneeRoleOptions }] = useAssigneeRoleOptions();
  const [hasFetchedAssignee, setHasFetchedAssignee] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate('appraisal_commission', currentCommission.id);

  useEffect(() => {
    hideEditButton && handleClick();
  }, [hideEditButton]);

  const assignees = useMemo(() => {
    if (showDialog) {
      let options = assigneeOptions.data?.assignees ?? [];
      if (identity?.role === 'appraisal_firm_limited_access') {
        options = options.filter((e) => e.id === identity?.id);
      }
      getAssigneeRoleOptions();
      return options;
    }
  }, [identity, assigneeOptions, showDialog]);

  const assigneeRoles = useMemo(() => {
    if (showDialog) {
      let options = assigneeRoleOptions?.assignee_roles ?? [];
      return options;
    }
  }, [assigneeRoleOptions, showDialog]);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangePercentage = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPercentage({ ...percentage, [prop]: event.target.value });
  };

  const { templatePropertyId, residentialId, commercialId, template, templateDefault } = {
    templatePropertyId: appraisal?.property?.property_type_id,
    residentialId:
      appraisal?.property?.property_type_id === 1 ? appraisal?.property?.residential_ownership_type_id : null,
    commercialId: appraisal?.property?.property_type_id === 2 ? appraisal?.property?.commercial_property_type_id : null,
    template: true,
    templateDefault: true,
  };

  const [getAssigneeData, { data: commissionData1 }] = useAssigneeCommission({
    assigneeId,
    roleId,
    templatePropertyId,
    residentialId,
    commercialId,
    template,
  });
  const [getAssigneeData2, { data: commissionData2 }] = useGenericCommissionQuery({
    assigneeId,
    template,
    templateDefault,
  });

  const templateData: any = useMemo(() => {
    if (showDialog && assigneeId) {
      if (commissionData1?.appraisal_commission?.length) {
        return commissionData1?.appraisal_commission[0];
      } else {
        getAssigneeData2();
        return '';
      }
    }
  }, [showDialog, commissionData1]);

  const defaultTemplateData: any = useMemo(() => {
    if (showDialog || !appraisal) {
      if (commissionData2?.appraisal_commission?.length) {
        return commissionData2?.appraisal_commission[0];
      } else return '';
    }
  }, [showDialog, commissionData2]);

  useEffect(() => {
    if (hasFetchedAssignee && assigneeId) {
      let temp = templateData ? templateData : defaultTemplateData;
      temp?.rate_type_id === 2 ? setFlat(true) : setFlat(false);
      if (temp?.rate_type_id === 1) {
        setFlat(false);
        setGross(temp?.net_expenses ? false : true);
        setPercentage({
          gross: temp?.net_expenses ? 0 : formatDecimalNumber(temp?.rate * 100, 2),
          net: temp?.net_expenses ? formatDecimalNumber(temp?.rate * 100, 2) : 0,
        });
        setValues({
          quantity: temp?.quantity,
          rate: temp?.rate,
        });
      } else {
        setFlat(true);
        setValues({
          quantity: temp?.quantity,
          rate: temp?.rate,
        });
      }
    }
  }, [templateData, defaultTemplateData]);

  const handleClick = () => {
    setShowDialog(true);
    moveToolbarBelow();
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    hideEditButton && triggerCloseModal();
  };

  const moveToolbarBelow = () => {
    setTimeout(() => {
      const toolbar = document.querySelector('#notes-popup .ql-toolbar') as any;
      const container = document.querySelector('#notes-popup .ql-container') as any;
      const editor = document.querySelector('#notes-popup .ql-editor') as any;
      if (toolbar && container && editor) {
        container.append(toolbar);
        container.style.border = 'none';
        editor.style.border = '1px solid rgba(0, 0, 0, 0.12)';
        editor.style.borderRadius = '3px';
      }
    }, 2);
  };
  const handleAssigneeSelect = (val: string) => {
    setAssigneeId(val);
    setHasFetchedAssignee(true);
    if (val) {
      appraisal ? getAssigneeData() : getAssigneeData2();
    }
  };
  const handleRoleSelect = (val: number) => {
    setRoleId(val);
  };

  const handleSubmit = async (values: any) => {
    const paid_status = {
      paid_date: displayFormattedDate(values.paid_date, 'yyyy-MM-dd'),
    };
    const values2 = {
      ...values,
      rate: formatDecimalNumber(isGross ? percentage?.gross / 100 : percentage?.net / 100, 2),
      ...(values?.paid_date && paid_status),
    };
    setDisabled(true);
    if (appraisal) {
      update(
        {
          payload: {
            id: currentCommission.id,
            data: values.rate_type_id === 2 ? { ...values, ...(values.paid_date && paid_status) } : values2,
            previousData: currentCommission,
          },
        },
        {
          onSuccess: () => {
            refetchCommission();
            setShowDialog(false);
            setDisabled(false);
            notify('appraisal_commission.updated');
            hideEditButton && triggerCloseModal();
            refresh();
          },
          onFailure: ({ error }: any) => {
            setDisabled(false);
            notify(error.message, 'error');
          },
        },
      );
    } else {
      let full_name = assignees?.find((each) => each?.id === values?.assignee_user_account_id)?.full_name;
      let role = assigneeRoles?.find((each) => each?.id === values?.assignee_role_id)?.role;
      const newCommissionData = {
        ...(values.rate_type_id === 2 ? values : values2),
        assignee_full_name: full_name,
        assignee_role: role,
      };
      onSave(newCommissionData);
    }
    setShowDialog(false);
    setDisabled(false);
  };
  let dataObj;
  if (hasFetchedAssignee && !appraisal) {
    if (assigneeId) dataObj = templateData ? templateData : defaultTemplateData;
  } else {
    dataObj = currentCommission;
  }

  return (
    <>
      {!hideEditButton && (
        <Icon onClick={handleClick} classes={{ root: classes.activeCursor }} fontSize="small">
          edit
        </Icon>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit Commission"
      >
        <FormWithRedirect
          initialValues={{
            appraisal_id: appraisal?.id,
            client_id: clientId,
            report_fee: appraisal?.report_fee,
            rate_type_id: isFlat ? 2 : 1,
            net_expenses: !isGross && !isFlat ? true : false,
            assignee_user_account_id: dataObj?.assignee_user_account_id,
            assignee_role_id: dataObj?.assignee_role_id,
            paid_date: dataObj?.paid_date,
            rate: dataObj ? dataObj?.rate : 0,
            percentage: dataObj ? formatDecimalNumber(dataObj?.rate * 100, 2) : 0,
            total_amount: appraisal
              ? (appraisal?.total_fees * (percentage?.gross / 100)).toFixed(2)
              : (reportFee * ((isGross ? percentage?.gross : percentage?.net) / 100)).toFixed(2),
          }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving, ...rest }: any) => (
            <DialogContent classes={{ root: popUpClasses.dialogContent }}>
              <Card variant="outlined" className={popUpClasses.formBottom}>
                <CardHeader title="Edit Assignee & Commission" classes={{ root: popUpClasses.cardHeader }}></CardHeader>
                <Divider></Divider>
                <Box className={popUpClasses.formContainer}>
                  <Typography classes={{ root: popUpClasses.heading }}>ASSIGNEE</Typography>
                  <Divider classes={{ root: popUpClasses.divider }}></Divider>
                  <Grid container direction="row">
                    <Grid item md={6} sm={8} xs={12}>
                      <AutocompleteInput
                        label="Assignee"
                        fullWidth
                        variant="outlined"
                        source="assignee_user_account_id"
                        optionText={(record: User_Profiles) => record?.full_name}
                        choices={assignees}
                        onChange={handleAssigneeSelect}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md={6} sm={8} xs={12}>
                      <AutocompleteInput
                        label="Role"
                        fullWidth
                        variant="outlined"
                        source="assignee_role_id"
                        optionText={(record: Assignee_Roles) => record?.role}
                        choices={assigneeRoles}
                        onChange={handleRoleSelect}
                      />
                    </Grid>
                  </Grid>
                  <Typography classes={{ root: popUpClasses.heading }}>COMMISSION</Typography>
                  <Divider classes={{ root: popUpClasses.divider }}></Divider>

                  <Grid container direction="row" style={{ marginBottom: '32px' }}>
                    <Button
                      label="Flat"
                      onClick={() => setFlat(true)}
                      variant={isFlat ? 'contained' : 'outlined'}
                      style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                    />
                    <Button
                      label="Percentage of Total Fees"
                      onClick={() => setFlat(false)}
                      variant={isFlat ? 'outlined' : 'contained'}
                      style={{ borderRadius: '24px', paddingRight: '16px' }}
                    />
                  </Grid>
                  {isFlat ? (
                    ''
                  ) : (
                    <Grid container direction="row" style={{ marginBottom: '32px' }}>
                      <Button
                        label="Gross Expenses"
                        onClick={() => setGross(true)}
                        variant={isGross ? 'contained' : 'outlined'}
                        style={{ borderRadius: '24px', marginRight: '16px', paddingRight: '16px' }}
                      />
                      <Button
                        label="Net Expenses"
                        onClick={() => setGross(false)}
                        variant={isGross ? 'outlined' : 'contained'}
                        style={{ borderRadius: '24px', paddingRight: '16px' }}
                      />
                    </Grid>
                  )}
                  <Grid container direction="row" justify="space-between">
                    {isFlat ? (
                      <>
                        <Grid item md={3} xs={12}>
                          <TextInput
                            validate={required()}
                            source={'quantity'}
                            variant="outlined"
                            type="number"
                            value={values.quantity}
                            onChange={handleChange('quantity')}
                            fullWidth
                            defaultValue={currentCommission.quantity}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextInput
                            validate={required()}
                            value={values.rate}
                            onChange={handleChange('rate')}
                            source="rate"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            fullWidth
                            defaultValue={currentCommission.rate}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Typography style={{ paddingTop: '12.5px' }}>
                            Fee: $
                            {isFlat
                              ? (values.rate * values.quantity).toFixed(2)
                              : ((values.rate / 100) * appraisal.total_fees).toFixed(2)}
                          </Typography>
                        </Grid>
                      </>
                    ) : isGross ? (
                      <>
                        <Typography style={{ fontSize: '16px' }}>
                          <b>Total fees:</b>
                          <br /> ${appraisal ? appraisal?.total_fees?.toFixed(2) : reportFee}
                        </Typography>
                        <Grid item md={3} xs={12}>
                          <TextInput
                            defaultValue={
                              !currentCommission?.net_expenses ? (currentCommission.rate * 100).toFixed(0) : ''
                            }
                            validate={required()}
                            source={'percentage'}
                            variant="outlined"
                            type="number"
                            value={percentage?.gross}
                            onChange={handleChangePercentage('gross')}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Typography style={{ paddingTop: '12.5px' }}>
                            Commission: $
                            {appraisal
                              ? (appraisal?.total_fees * (percentage?.gross / 100)).toFixed(2)
                              : (reportFee * (percentage?.gross / 100)).toFixed(2)}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Typography style={{ fontSize: '16px' }}>
                          <b>Total Fees:</b>
                          <br /> ${appraisal ? appraisal?.total_fees?.toFixed(2) : reportFee}
                        </Typography>
                        {appraisal && (
                          <>
                            <Typography style={{ fontSize: '16px' }}>
                              <b>Total expenses:</b>
                              <br /> ${(appraisal?.total_expenses).toFixed(2)}
                            </Typography>
                            <Typography style={{ fontSize: '16px' }}>
                              <b>Fee - Expenses:</b>
                              <br /> ${(appraisal?.total_fees - appraisal?.total_expenses).toFixed(2)}
                            </Typography>
                          </>
                        )}
                        <Grid item md={3} xs={12}>
                          <TextInput
                            defaultValue={
                              currentCommission?.net_expenses ? (currentCommission.rate * 100).toFixed(0) : ''
                            }
                            validate={required()}
                            source={'percentage'}
                            variant="outlined"
                            type="number"
                            value={percentage?.net}
                            onChange={handleChangePercentage('net')}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Typography style={{ paddingTop: '12.5px' }}>
                            Commission: $
                            {appraisal
                              ? ((appraisal?.total_fees - appraisal?.total_expenses) * (percentage?.net / 100)).toFixed(
                                  2,
                                )
                              : (reportFee * (percentage?.net / 100)).toFixed(2)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {appraisal && (
                    <>
                      <Typography classes={{ root: popUpClasses.heading }}>STATUS</Typography>
                      <Divider classes={{ root: popUpClasses.divider }}></Divider>
                      <Grid container direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                          <DateInput variant="outlined" fullWidth label="Paid" source="paid_date" />
                        </Grid>
                      </Grid>
                      <Divider classes={{ root: popUpClasses.divider }}></Divider>
                    </>
                  )}
                  <DialogActions classes={{ root: popUpClasses.editActions }}>
                    <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                      <IconCancel />
                    </Button>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading || disabled}
                    />
                  </DialogActions>
                </Box>
              </Card>
            </DialogContent>
          )}
        />
      </Dialog>
    </>
  );
}

export default EditCommissionButton;
